






// // import React, { useState, useEffect } from 'react';
// // import './Home.css';
// // import slide1 from '../../Assets/slide1.jpg';
// // import slide2 from '../../Assets/slide2.jpg';
// // import slide3 from '../../Assets/slide3.jpg';
// // import slide4 from '../../Assets/slide4.jpg';
// // import slide5 from '../../Assets/slide5.jpg';
// // import Footer from '../Footer/Footer'; 

// // const slides = [slide1, slide2, slide3, slide4, slide5];

// // function Home() {
// //   const [currentSlide, setCurrentSlide] = useState(0);

// //   useEffect(() => {
// //     const interval = setInterval(() => {
// //       setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
// //     }, 5000); 

// //     return () => clearInterval(interval); 
// //   }, []);

// //   return (
// //     <div className="home">
// //       <div className="slideshow">
// //         {slides.map((slide, index) => (
// //           <div
// //             key={index}
// //             className={`slide ${index === currentSlide ? 'active' : ''}`}
// //             style={{ backgroundImage: `url(${slide})` }}
// //           ></div>
// //         ))}
// //       </div>
// //       <div className="content">
// //         <h1>Home</h1>
// //         <p>Welcome to the home page</p>
// //       </div>
// //       <Footer /> 

// //     </div>
    
// //   );

  
  
// // }

// // export default Home;





// // import React, { useState, useEffect } from 'react';
// // import './Home.css';
// // import slide1 from '../../Assets/slide1.jpg';
// // import slide2 from '../../Assets/slide2.jpg';
// // import slide3 from '../../Assets/slide3.jpg';
// // import slide4 from '../../Assets/slide4.jpg';
// // import slide5 from '../../Assets/slide5.jpg';
// // import Footer from '../Footer/Footer';

// // const slides = [slide1, slide2, slide3, slide4, slide5];

// // function Home() {
// //   const [currentSlide, setCurrentSlide] = useState(0);

// //   useEffect(() => {
// //     const interval = setInterval(() => {
// //       setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
// //     }, 5000); 

// //     return () => clearInterval(interval); 
// //   }, []);

// //   const handleDotClick = (index) => {
// //     setCurrentSlide(index);
// //   };

// //   return (
// //     <div className="home">
// //       <div className="slideshow">
// //         {slides.map((slide, index) => (
// //           <div
// //             key={index}
// //             className={`slide ${index === currentSlide ? 'active' : ''}`}
// //             style={{ backgroundImage: `url(${slide})` }}
// //           ></div>
// //         ))}
// //       </div>
// //       <div className="content">
// //         <h1>Home</h1>
// //         <p>Welcome to the home page</p>
// //       </div>
// //       <div className="dot-container">
// //         {slides.map((_, index) => (
// //           <div
// //             key={index}
// //             className={`dot ${index === currentSlide ? 'active' : ''}`}
// //             onClick={() => handleDotClick(index)}
// //           ></div>
// //         ))}
// //       </div>
// //       <Footer /> 
// //     </div>
// //   );
// // }

// // export default Home;







// import React, { useState, useEffect } from 'react';
// import './Home.css';
// import slide1 from '../../Assets/slide1.jpg';
// import slide2 from '../../Assets/slide2.jpg';
// import slide3 from '../../Assets/slide3.jpg';
// import slide4 from '../../Assets/slide4.jpg';
// import slide5 from '../../Assets/slide5.jpg';
// import Footer from '../Footer/Footer';

// const slides = [slide1, slide2, slide3, slide4, slide5];

// function Home() {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
//     }, 5000); // Change slide every 5 seconds

//     return () => clearInterval(interval); // Clean up interval on component unmount
//   }, []);

//   const handleDotClick = (index) => {
//     setCurrentSlide(index);
//   };

//   return (
//     <div className="home">
//       <div className="slideshow">
//         {slides.map((slide, index) => (
//           <div
//             key={index}
//             className={`slide ${index === currentSlide ? 'active' : ''}`}
//             style={{ backgroundImage: `url(${slide})` }}
//           ></div>
//         ))}
//       </div>
//       <div className="content">
//         <h1>Home</h1>
//         <p>Welcome to the home page</p>
//       </div>
//       <div className="dot-container">
//         {slides.map((_, index) => (
//           <div
//             key={index}
//             className={`dot ${index === currentSlide ? 'active' : ''}`}
//             onClick={() => handleDotClick(index)}
//           ></div>
//         ))}
//       </div>
//       <div className="company-info">
//         <h2>About ELLAMMAN TECHNOLOGY</h2>
//         <p>
//           ELLAMMAN TECHNOLOGY Private Limited, established in 2018 and registered as a private limited company in 2024, is a rapidly growing company specializing in test and measurement equipment, optical & microwave components, cable assemblies, and cable connectors. The company focuses on key industries such as GSM, CDMA, GPS, and ISM bands.
//         </p>
//         <p>
//           As a leading supplier and trader, ELLAMMAN TECHNOLOGY provides a broad range of high-quality products, including spectrum analyzers, VNAs (Vector Network Analyzers), EMF testers, TDM testers, Ethernet analyzers, OTDR (Optical Time Domain Reflectometers), power meters, and light sources. The product portfolio also includes coaxial connectors, RF connectors, testing cables, optical & microwave components, duplexers, filters, surge arrestors, combiners, attenuators, dummy loads, RF optical switches, and waveguide components.
//         </p>
//         <p>
//           In addition to its core offerings, the company engages in trading coaxial cables and optical fiber products. ELLAMMAN TECHNOLOGY is recognized for its strength in supplying materials for IBS (In-Building Solutions), BTS (Base Transceiver Stations), and data center installations. With a strong commitment to customer satisfaction and continuous product improvement, ELLAMMAN TECHNOLOGY strives to meet and exceed the expectations of its clients.
//         </p>
//       </div>
//       <Footer /> {/* Include the Footer component */}
//     </div>
//   );
// }

// export default Home;







// import React, { useState, useEffect } from 'react';
// import './Home.css';
// import slide1 from '../../Assets/slide1.jpg';
// import slide2 from '../../Assets/slide2.jpg';
// import slide3 from '../../Assets/slide3.jpg';
// import slide4 from '../../Assets/slide4.jpg';
// import slide5 from '../../Assets/slide5.jpg';
// import Footer from '../Footer/Footer'; 

// const slides = [slide1, slide2, slide3, slide4, slide5];

// function Home() {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
//     }, 5000); 

//     return () => clearInterval(interval); 
//   }, []);

//   return (
//     <div className="home">
//       <div className="slideshow">
//         {slides.map((slide, index) => (
//           <div
//             key={index}
//             className={`slide ${index === currentSlide ? 'active' : ''}`}
//             style={{ backgroundImage: `url(${slide})` }}
//           ></div>
//         ))}
//       </div>
//       <div className="content">
//         <h1>Home</h1>
//         <p>Welcome to the home page</p>
//       </div>
//       <Footer /> 

//     </div>
    
//   );

  
  
// }

// export default Home;





// import React, { useState, useEffect } from 'react';
// import './Home.css';
// import slide1 from '../../Assets/slide1.jpg';
// import slide2 from '../../Assets/slide2.jpg';
// import slide3 from '../../Assets/slide3.jpg';
// import slide4 from '../../Assets/slide4.jpg';
// import slide5 from '../../Assets/slide5.jpg';
// import Footer from '../Footer/Footer';

// const slides = [slide1, slide2, slide3, slide4, slide5];

// function Home() {
//   const [currentSlide, setCurrentSlide] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
//     }, 5000); 

//     return () => clearInterval(interval); 
//   }, []);

//   const handleDotClick = (index) => {
//     setCurrentSlide(index);
//   };

//   return (
//     <div className="home">
//       <div className="slideshow">
//         {slides.map((slide, index) => (
//           <div
//             key={index}
//             className={`slide ${index === currentSlide ? 'active' : ''}`}
//             style={{ backgroundImage: `url(${slide})` }}
//           ></div>
//         ))}
//       </div>
//       <div className="content">
//         <h1>Home</h1>
//         <p>Welcome to the home page</p>
//       </div>
//       <div className="dot-container">
//         {slides.map((_, index) => (
//           <div
//             key={index}
//             className={`dot ${index === currentSlide ? 'active' : ''}`}
//             onClick={() => handleDotClick(index)}
//           ></div>
//         ))}
//       </div>
//       <Footer /> 
//     </div>
//   );
// }

// export default Home;







import React, { useState, useEffect } from 'react';
import './Home.css';
import slide1 from '../../Assets/slide1.jpg';
import slide2 from '../../Assets/slide2.jpg';
import slide3 from '../../Assets/slide3.jpg';
import slide4 from '../../Assets/slide4.jpg';
import slide5 from '../../Assets/slide5.jpg';
import Footer from '../Footer/Footer';

const slides = [slide1, slide2, slide3, slide4, slide5];

function Home() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  const handleDotClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className="home">
      <div className="slideshow">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`slide ${index === currentSlide ? 'active' : ''}`}
            style={{ backgroundImage: `url(${slide})` }}
          ></div>
        ))}
      </div>
      {/* <div className="content">
        <h1>Home</h1>
        <p>Welcome to the home page</p>
      </div> */}
      <div className="dot-container">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === currentSlide ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          ></div>
        ))}
      </div>
      <div className="company-info">
        <h2>About ELLAMMAN TECHNOLOGY</h2>
        <p>
          ELLAMMAN TECHNOLOGY Private Limited, established in 2018 and registered as a private limited company in 2024, is a rapidly growing company specializing in test and measurement equipment, optical & microwave components, cable assemblies, and cable connectors. The company focuses on key industries such as GSM, CDMA, GPS, and ISM bands.
        </p>
        <p>
          As a leading supplier and trader, ELLAMMAN TECHNOLOGY provides a broad range of high-quality products, including spectrum analyzers, VNAs (Vector Network Analyzers), EMF testers, TDM testers, Ethernet analyzers, OTDR (Optical Time Domain Reflectometers), power meters, and light sources. The product portfolio also includes coaxial connectors, RF connectors, testing cables, optical & microwave components, duplexers, filters, surge arrestors, combiners, attenuators, dummy loads, RF optical switches, and waveguide components.
        </p>
        <p>
          In addition to its core offerings, the company engages in trading coaxial cables and optical fiber products. ELLAMMAN TECHNOLOGY is recognized for its strength in supplying materials for IBS (In-Building Solutions), BTS (Base Transceiver Stations), and data center installations. With a strong commitment to customer satisfaction and continuous product improvement, ELLAMMAN TECHNOLOGY strives to meet and exceed the expectations of its clients.
        </p>
      </div>
      <Footer /> {/* Include the Footer component */}
    </div>
  );
}

export default Home;

import React from 'react';
import './Services.css';
import Footer from '../Footer/Footer';
import { FaMicroscope, FaWaveSquare, FaTools, FaSatelliteDish, FaNetworkWired, FaBolt, FaShieldAlt, FaIndustry } from 'react-icons/fa';

const servicesData = [
    {
        title: 'Test and Measurement Equipment',
        description: 'We provide a wide range of testing and measurement equipment including spectrum analyzers, VNAs, EMF testers, and OTDR modules for precise diagnostics.',
        icon: <FaMicroscope />,
    },
    {
        title: 'Optical & Microwave Components',
        description: 'Our portfolio includes optical components like power meters, light sources, RF connectors, and waveguide components, essential for robust network operations.',
        icon: <FaWaveSquare />,
    },
    {
        title: 'Installation & Commissioning Services',
        description: 'We offer professional installation and commissioning services for data centers, telecom networks, and other critical infrastructure.',
        icon: <FaTools />,
    },
    {
        title: 'RF and Optical Testing Solutions',
        description: 'Specialized in RF testing solutions including coaxial connectors, surge arrestors, combiners, and GPS accessories for advanced testing environments.',
        icon: <FaSatelliteDish />,
    },
    {
        title: 'Data Center Solutions',
        description: 'We provide data center cable management, acceptance testing, and comprehensive maintenance services to ensure optimal performance.',
        icon: <FaNetworkWired />,
    },
    {
        title: 'Power and Utility Components',
        description: 'Our products cater to the power and utility sector, including robust solutions for power meters, optical test sets, and variable optical attenuators.',
        icon: <FaBolt />,
    },
    {
        title: 'Security and Surveillance Solutions',
        description: 'Offering high-quality RF and optical switches and dummy loads for secure and reliable surveillance and security operations.',
        icon: <FaShieldAlt />,
    },
    {
        title: 'Engineering and Technical Services',
        description: 'Our expert engineering services support manufacturing, development, and high-precision testing for industries such as defense and telecom.',
        icon: <FaIndustry />,
    }
];

const Services = () => {
    return (
        <div className="services-container">
            <h1 className="text-center py-4">Our Services</h1>
            <div className="services-list">
                {servicesData.map((service, index) => (
                    <div className="service-card" key={index}>
                        <div className="service-icon">{service.icon}</div>
                        <h2>{service.title}</h2>
                        <p>{service.description}</p>
                    </div>
                ))}
            </div>
            <Footer /> {/* Include the Footer component */}
        </div>
    );
}

export default Services;

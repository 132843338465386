



// About.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import slide1 from '../../Assets/slide1.jpg'; // Example image used for all sections
import './About.css';
import Footer from '../Footer/Footer';

// Import images from the Assets folder
import slide1 from '../../Assets/slide1.jpg';
import slide2 from '../../Assets/slide2.jpg';
import slide3 from '../../Assets/slide3.jpg';
import slide4 from '../../Assets/slide4.jpg';
import slide5 from '../../Assets/slide5.jpg';
import slide6 from '../../Assets/slide6.png';
import slide7 from '../../Assets/Connector-Family.jpg';
import slide8 from '../../Assets/pexels-photo-918283.jpeg';

function About() {
  return (
    <div className="about-page">
      <header className="about-header text-center py-5 bg-light">
        <h1>About Us</h1>
        <p className="lead mx-auto">
          Welcome to <strong>ELLAMMAN TECHNOLOGY Private Limited</strong>. Established in 2018 and registered in 2024, we specialize in providing high-quality test and measurement equipment, optical and microwave components, cable assemblies, and connectors.
        </p>
      </header>

      {/* Banner Section */}
      <section className="about-banner">
        <img src={slide1} alt="Company Banner" className="img-fluid w-100" />
      </section>

      <section className="container about-content py-5">
        <h2 className="text-center mb-4">Our Expertise</h2>
        <div className="row align-items-center">
          <div className="col-md-6">
            <p>
              ELLAMMAN TECHNOLOGY is a leading supplier of high-quality products, including spectrum analyzers, Vector Network Analyzers (VNAs), EMF testers, TDM testers, Ethernet analyzers, Optical Time Domain Reflectometers (OTDR), power meters, and light sources. Our portfolio also features coaxial connectors, RF connectors, testing cables, optical & microwave components, duplexers, filters, surge arrestors, combiners, attenuators, and waveguide components.
            </p>
          </div>
          <div className="col-md-6">
            <img src={slide2} alt="Our Expertise" className="img-fluid rounded" />
          </div>
        </div>

        <h2 className="text-center mt-5 mb-4">Our Commitment</h2>
        <div className="row align-items-center">
          <div className="col-md-6 order-md-2">
            <p>
              Committed to customer satisfaction, we focus on continuous product improvement and exceptional service. We are recognized for our expertise in supplying materials for In-Building Solutions (IBS), Base Transceiver Stations (BTS), and data center installations, striving to exceed client expectations across various sectors.
            </p>
          </div>
          <div className="col-md-6 order-md-1">
            <img src={slide3} alt="Our Commitment" className="img-fluid rounded" />
          </div>
        </div>

        <h2 className="text-center mt-5 mb-4">Our Markets</h2>
        <ul className="list-group">
          <li className="list-group-item">Telecom/Wireless</li>
          <li className="list-group-item">Data Centers and Enterprise</li>
          <li className="list-group-item">Defense/Military</li>
          <li className="list-group-item">Utilities (Power, Oil & Gas)</li>
          <li className="list-group-item">Manufacturing Development & Institute</li>
        </ul>

        <h2 className="text-center mt-5 mb-4">Our Services</h2>
        <p>
          Beyond our core products, we offer trading services for coaxial cables and optical fiber products. Our service portfolio includes data center cable management, installation & commissioning (I&C), acceptance testing, and comprehensive engineering services to meet various industry needs.
        </p>

        <h2 className="text-center mt-5 mb-4">Product Highlights</h2>
        <div className="row">
          <div className="col-md-4 mb-4">
            <img src={slide4} alt="Optical Power Meters" className="img-fluid rounded mb-3" />
            <h5>Optical Power Meters</h5>
            <p>
              Our optical power meters provide precise testing capabilities for optical fiber networks, including next-gen PON power meters and fiber inspection scopes, enhancing network reliability.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <img src={slide5} alt="RF and Optical Components" className="img-fluid rounded mb-3" />
            <h5>RF and Optical Components</h5>
            <p>
              We supply essential components such as RF connectors, GPS accessories, coaxial cables, and optical transceivers, supporting robust telecom and data center operations.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <img src={slide6} alt="Advanced Testing Equipment" className="img-fluid rounded mb-3" />
            <h5>Advanced Testing Equipment</h5>
            <p>
              Our testing equipment, including handheld optical testers, copper testers, and cable fault locators, ensures comprehensive diagnostics for various networking environments.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <img src={slide7} alt="PTP Grandmaster Timing" className="img-fluid rounded mb-3" />
            <h5>PTP Grandmaster Timing</h5>
            <p>
              Featuring OCXO/Rubidium oscillators, our PTP Grandmaster solutions offer high-precision timing for synchronization in critical infrastructure.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <img src={slide8} alt="OTDR Modules" className="img-fluid rounded mb-3" />
            <h5>OTDR Modules</h5>
            <p>
              Our OTDR modules, with ranges up to 50dB, provide detailed analysis for fiber optic installations, ensuring optimal performance and fault detection.
            </p>
          </div>
          <div className="col-md-4 mb-4">
            <img src={slide1} alt="GNSS Antenna & GPS Accessories" className="img-fluid rounded mb-3" />
            <h5>GNSS Antenna & GPS Accessories</h5>
            <p>
              We offer a range of GPS accessories, including GNSS antennas and GPS splitters, crucial for enhancing the accuracy and reliability of navigation systems.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default About;

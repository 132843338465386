
import React from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import Home from './Components/Home/Home';
import About from './Components/About/About';
import Services from './Components/Services/Services';
import Product from './Components/Product/Product';
import Navbar from './Components/Navbar/Navbar'; // Import Navbar component

import ContactUs from './Components/ContactUs/ContactUs';
import Footer from './Components/Footer/Footer'; // Make sure Footer is imported correctly

import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        {/* <nav>
          <div className="brand">ELLAMMAN</div>
          <ul>
            <li>
              <NavLink exact to="/" activeClassName="active">Home</NavLink>
            </li>
            <li>
              <NavLink to="/product" activeClassName="active">Products</NavLink>
            </li>
            <li>
              <NavLink to="/services" activeClassName="active">Services</NavLink>
            </li>
            <li>
              <NavLink to="/contactUs" activeClassName="active">Contact Us</NavLink>
            </li>
            <li>
              <NavLink to="/about" activeClassName="active">About</NavLink>
            </li>
          </ul> */}
        {/* </nav> */}

<Navbar/>
        {/* Add main content container */}
        <div className="main-content">
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contactUs" element={<ContactUs />} />
            <Route path="/services" element={<Services />} />
            <Route path="/product" element={<Product />} />
          </Routes>
        </div>

        {/* Ensure footer is inside the main app, not fixed */}
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;

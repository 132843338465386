
// import React, { useState } from 'react';
// import { NavLink } from 'react-router-dom';
// import './Navbar.css'; // Import the updated Navbar styles

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <nav>
//       <div className="brand">ELLAMMAN</div>
//       <div className="menu-toggle" onClick={toggleMenu}>
//         <span className="bar"></span>
//         <span className="bar"></span>
//         <span className="bar"></span>
//       </div>
//       <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
//         <li>
//           <NavLink exact to="/" activeClassName="active">Home</NavLink>
//         </li>
//         <li>
//           <NavLink to="/product" activeClassName="active">Products</NavLink>
//         </li>
//         <li>
//           <NavLink to="/services" activeClassName="active">Services</NavLink>
//         </li>
//         <li>
//           <NavLink to="/contactUs" activeClassName="active">Contact Us</NavLink>
//         </li>
//         <li>
//           <NavLink to="/about" activeClassName="active">About</NavLink>
//         </li>
//       </ul>
//     </nav>
//   );
// };

// export default Navbar;





// Components/Navbar/Navbar.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css'; // Import the updated Navbar styles

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Toggle the menu visibility
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close the menu when a link is clicked
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav>
      <div className="brand">ELLAMMAN</div>
      <div className="menu-toggle" onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>
      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li>
          <NavLink exact to="/" activeClassName="active" onClick={closeMenu}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/product" activeClassName="active" onClick={closeMenu}>
            Products
          </NavLink>
        </li>
        <li>
          <NavLink to="/services" activeClassName="active" onClick={closeMenu}>
            Services
          </NavLink>
        </li>
        <li>
          <NavLink to="/contactUs" activeClassName="active" onClick={closeMenu}>
            Contact Us
          </NavLink>
        </li>
        <li>
          <NavLink to="/about" activeClassName="active" onClick={closeMenu}>
            About
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;

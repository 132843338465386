// Product.js
import React from 'react';
import './Product.css';
import { FaMicroscope, FaWaveSquare, FaSatelliteDish, FaCogs, FaPlug, FaShieldAlt, FaBroadcastTower, FaNetworkWired } from 'react-icons/fa'; // Icons for visual appeal
import Footer from '../Footer/Footer';


// Import images from the Assets folder
import slide1 from '../../Assets/slide1.jpg';
import slide2 from '../../Assets/slide2.jpg';
import slide3 from '../../Assets/slide3.jpg';
import slide4 from '../../Assets/slide4.jpg';
import slide5 from '../../Assets/slide5.jpg';
import slide6 from '../../Assets/slide6.png';
import slide7 from '../../Assets/Connector-Family.jpg';
import slide8 from '../../Assets/pexels-photo-918283.jpeg';

// Product data extracted from the provided document
const productData = [
    {
        title: 'Test and Measurement Equipment',
        description: 'Includes spectrum analyzers, VNAs, EMF testers, TDM testers, Ethernet analyzers, OTDR modules, power meters, and light sources, designed for precise diagnostics.',
        icon: <FaMicroscope />,
        image: slide1 // Use the slide1 image
    },
    {
        title: 'Optical & Microwave Components',
        description: 'Our portfolio includes coaxial connectors, RF connectors, testing cables, optical components, duplexers, filters, surge arrestors, and waveguide components.',
        icon: <FaWaveSquare />,
        image: slide2 // Use the slide1 image
    },
    {
        title: 'RF and Optical Testing Solutions',
        description: 'Specialized testing solutions including RF switches, combiners, attenuators, dummy loads, and fiber inspection scopes for advanced testing environments.',
        icon: <FaSatelliteDish />,
        image: slide3 // Use the slide1 image
    },
    {
        title: 'Installation & Commissioning',
        description: 'Professional services for installation and commissioning of data centers, telecom networks, and critical infrastructure components.',
        icon: <FaCogs />,
        image: slide4 // Use the slide1 image
    },
    {
        title: 'Power and Utility Components',
        description: 'Products for power utilities including optical test sets, variable optical attenuators, power meters, and specialized optical testers.',
        icon: <FaPlug />,
        image: slide5 // Use the slide1 image
    },
    {
        title: 'Security and Surveillance Solutions',
        description: 'Offering RF and optical switches, GPS accessories, GNSS antennas, and secure components for surveillance and defense applications.',
        icon: <FaShieldAlt />,
        image: slide6 // Use the slide1 image
    },
    {
        title: 'Telecom and Wireless Products',
        description: 'Products tailored for GSM, CDMA, GPS, and ISM bands, including BTS materials, RF connectors, and coaxial cables.',
        icon: <FaBroadcastTower />,
        image: slide1 // Use the slide1 image
    },
    {
        title: 'Data Center and Network Components',
        description: 'Comprehensive solutions for data center management, including cabling, I&C, acceptance testing, and waveguide components.',
        icon: <FaNetworkWired />,
        image: slide8 // Use the slide1 image
    }
];

const Product = () => {
    return (
        <div className="product-page">
            <h1 className="text-center py-4">Our Products</h1>
            <div className="container product-list">
                <div className="row">
                    {productData.map((product, index) => (
                        <div className="col-md-4 mb-4" key={index}>
                            <div className="product-card card shadow-sm h-100">
                                <img src={product.image} className="card-img-top" alt={product.title} />
                                <div className="card-body text-center">
                                    <div className="product-icon mb-3">{product.icon}</div>
                                    <h5 className="card-title">{product.title}</h5>
                                    <p className="card-text">{product.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Product;


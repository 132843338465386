import React from 'react';
import './ContactUs.css';
import Footer from '../Footer/Footer'; 

const ContactUs = () => {
    return (
        <div className="contact-container">
            <h1>Contact Us</h1>
            <div className="contact-info">

    

                <div className="contact-details">
                    <h2>Our Office</h2>
                    <p>Address: ROSE-VALE OXBRIDGE, UMA SH, PATNA CITY, SAMPATCHAK</p>
                    <p>Patna, Bihar(800008)</p>
                    <p>Phone: (+91) 9667149306</p>
                    <p>Email: info@ellaman.com</p>
                </div>
                <div className="contact-form">
                    <h2>Send Us a Message</h2>
                    <form>
                        <label>Name</label>
                        <input type="text" name="name" required />
                        <label>Email</label>
                        <input type="email" name="email" required />
                        <label>Subject</label>
                        <input type="text" name="subject" required />
                        <label>Message</label>
                        <textarea name="message" required></textarea>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            </div>
            <Footer /> {/* Include the Footer component */}
        </div>
    );
}

export default ContactUs;
